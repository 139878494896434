import type { MultiValueProps } from 'react-select';
import { components } from 'react-select';
import type { OptionSportsType } from '@/app/[locale]/projects/model';

export const MultiValue = (props: MultiValueProps<OptionSportsType>) => {
  const { index, getValue } = props;

  const maxToShow = 3;

  const overflow = getValue()
    .slice(maxToShow)
    .map(x => x.label);

  const title = overflow.filter(i => typeof i === 'string').join(', ');

  if (index < maxToShow)
    return <components.MultiValue {...props} />;

  return index === maxToShow
    ? (
      <span className="sports-filter__counter" title={title}>
        {`+ ${overflow.length}`}
      </span>
      )
    : null;
};
