import { VtmnSkeleton } from '@vtmn/react';
import { MainColumn } from '@/app/components/MainColumn';

export const ProjectsFiltersSkeleton = () => {
  return (
    <div className="projects-filter">
      <MainColumn>
        <div className="projects-filter__inner">
          <div className="projects-filter__sport">
            <VtmnSkeleton height="40px" />
          </div>
        </div>
      </MainColumn>
    </div>
  );
};
