import { useCallback, useEffect, useState } from 'react';
import { useLocale } from 'next-intl';
import type { Collection } from '../actions/collection';
import { fetchCollection } from '../actions/server';
import { useAuthenticatedContext } from '../components/Provider';
import { ApiRoute } from '../utils/request/api-routes';
import { useError } from './useError';
import type { SportType } from '~/model/SportType';
import { useRequestState } from '~/hooks/request/useRequestState';
import { AcceptLanguage } from '~/const/locale';

export function useUserFavoriteSports() {
  const locale = useLocale();
  const [favoriteSports, setFavoriteSports] = useState<Collection<SportType>>(null);

  const { user } = useAuthenticatedContext();

  const { startRequest, handleSuccess, handleError } = useRequestState();
  const { setError } = useError();

  const handleFetchUserFavoriteSports = useCallback(async () => {
    if (!user?.id) {
      return;
    }

    try {
      startRequest();
      const fetchedFavoriteSports = await fetchCollection<SportType>({
        pathname: `${ApiRoute.USERS}/${user.id}/${ApiRoute.SPORTS}`,
        revalidate: 0,
        headers: {
          acceptLanguage: AcceptLanguage[locale],
        },
        unpaginate: true,
      });

      setFavoriteSports(fetchedFavoriteSports);
      handleSuccess();
    } catch (error) {
      handleError();
      setError(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    handleFetchUserFavoriteSports();
  }, [handleFetchUserFavoriteSports]);

  return { favoriteSports };
}
