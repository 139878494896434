import { VpIcon } from '@vtmn-play/react/headless';
import type { ClearIndicatorProps } from 'react-select';
import { components } from 'react-select';

export const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <VpIcon name="close" />
    </components.ClearIndicator>
  );
};
