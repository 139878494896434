'use client';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslations } from 'next-intl';
import { VpButton } from '@vtmn-play/react/headless';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { ProjectsListItem, ProjectsListSkeleton } from '../components';
import { OptionsStatusValueType } from '../model';
import { ProjectsListEmpty } from './ProjectsListEmpty';
import { getPaginationNextPageNumber } from '~/api/common/callApi';
import { ProjectItemContextEnum } from '@/app/[locale]/(home)/components/ProjectsScroller/model';
import { MainColumn } from '@/app/components/MainColumn';
import { useAuthenticatedContext, useProjectsContext } from '@/app/components/Provider';
import type { ProjectType } from '~/model/ProjectType';
import type { Collection } from '@/app/actions/collection';
import type { ProjectTagType } from '~/model/ProjectTag';
import { usePathname, useRouter } from '@/i18n/routing';

export function ProjectsList({
  projects: firstProjects,
  tags: firstTags,
}: {
  projects?: Collection<ProjectType>;
  tags?: Collection<ProjectTagType>;
}) {
  const t = useTranslations('HOMEPAGE');
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const { user, isAuthenticating, isAuthenticated } = useAuthenticatedContext();

  const {
    fetchProjects,
    handleSetProjects,
    handleSetTags,
    isFetching,
    projects,
    sportsFilter,
    tags,
  } = useProjectsContext();

  const statusSearchParams = searchParams.get('status');
  const projectCollections = (projects || firstProjects);
  const items = projectCollections?.['hydra:member'];
  const nextPageNumber = getPaginationNextPageNumber(projectCollections?.['hydra:view']);

  const hasMoreToScroll = nextPageNumber < 4;

  const hasMoreToPaginate
  = !!projectCollections?.['hydra:view']['hydra:next'] && !hasMoreToScroll;

  const shouldBeAuthenticated = statusSearchParams && statusSearchParams !== OptionsStatusValueType.all;

  useEffect(() => {
    user?.id && fetchProjects(statusSearchParams || OptionsStatusValueType.all);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, statusSearchParams, sportsFilter]);

  useEffect(() => {
    !user?.id && sportsFilter && fetchProjects();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportsFilter, user?.id]);

  useEffect(() => {
    !isAuthenticating && !isAuthenticated && shouldBeAuthenticated && router.replace(pathname);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticating, isAuthenticated]);

  useEffect(() => {
    handleSetProjects(firstProjects);
    handleSetTags(firstTags);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainColumn>
        {!projectCollections
          ? <ProjectsListSkeleton />
          : (
            <InfiniteScroll
              dataLength={items.length || 0}
              next={() => fetchProjects(statusSearchParams)}
              hasMore={hasMoreToScroll}
              loader={<ProjectsListSkeleton />}
            >
              <ul className="projects-list" data-testid="projects-list">
                {items.map((project, index) => {
                  return (
                    <li key={project['@id']}>
                      <ProjectsListItem
                        project={project}
                        instanceName={ProjectItemContextEnum.all}
                        imagePriority={index < 15}
                        imageSizes="(max-width: 768px) 50vw, (max-width: 992px) 25vw, 15vw"
                        tags={(tags || firstTags)?.['hydra:member']}
                      />
                    </li>
                  );
                })}
              </ul>
              {hasMoreToPaginate && (
                isFetching
                  ? <ProjectsListSkeleton />
                  : (
                    <div className="projects__more">
                      <VpButton onClick={() => fetchProjects(statusSearchParams)}>
                        {t('SEE_MORE_PROJECTS')}
                      </VpButton>
                    </div>
                    )
              )}
            </InfiniteScroll>
            )}
      </MainColumn>
      {items?.length === 0 && (
        <ProjectsListEmpty />
      )}
    </>
  );
};
