'use client';

import { VpIcon } from '@vtmn-play/react/headless';
import Select from 'react-select';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { IconOption } from './IconOption';
import { MultiValue } from './MultiValue';
import { DropdownIndicator } from './DropdownIndicator';
import { ClearIndicator } from './ClearIndicator';
import type { OptionSportsType } from '@/app/[locale]/projects/model';
import layout from '~/const/layout';
import { useProjectsContext } from '@/app/components/Provider';
import { useSportsContext } from '@/app/components/Provider/SportsProvider';
import { capitalizeString } from '~/utils/utils';
import { useUserFavoriteSports } from '@/app/hooks/useUserFavoriteSports';

export function ProjectsFiltersSports() {
  const t = useTranslations('SPORTS_FILTER');

  const { sports, handleFetchSports, isFetching: isFetchingSports } = useSportsContext();

  const { favoriteSports } = useUserFavoriteSports();

  const { sportsFilter, handleSetSportsFilter, isFetching } = useProjectsContext();

  const [inputValue, setInputValue] = useState('');

  const userSports = favoriteSports?.['hydra:member']?.map(sport => ({
    id: sport.id,
    label: sport.label,
    value: sport['@id'],
    alias: sport.alias,
  }));

  const favoriteSportsUri = userSports?.map(sport => sport.value);

  const getUserFavouriteSportsFilterValue = (
  ) => {
    if (!userSports?.length)
      return null;

    return { id: userSports.map(sport => sport.id), label: (
      <span title={userSports.map(s => s.label).join(', ')}>
        <span className="projects-filter__select-favourite-icon-prefix">
          <VpIcon name="star" size={16} />
        </span>
        {t('MY_FAVOURITE_SPORTS', { count: userSports.length })}
      </span>
    ), title: t('MY_FAVOURITE_SPORTS'), mysports: userSports || [], value: 'mysports' };
  };

  const defineSportsOptions = () => {
    const options = sports?.['hydra:member'].length > 0
      ? sports?.['hydra:member'].map((sport) => {
        const isFavourite = favoriteSportsUri?.includes(sport['@id']);

        const isFavouriteSportsApplied = sportsFilter?.some(
          sportOption => sportOption.mysports,
        );

        const label = capitalizeString(sport.label);

        return {
          id: sport.id,
          title: label,
          label,
          value: sport['@id'],
          isDisabled: isFavourite && isFavouriteSportsApplied,
          isFavourite,
          alias: sport.alias,
        };
      })
      : [];

    // check if "my favourite sports" option is selected
    const isFilteredByFavouriteSports = sportsFilter?.length > 0
      && sportsFilter.find(option => option.mysports);

    // add "my favourite sports" option if user has favourite sports,
    // and if this option has not already been selected
    if (
      userSports?.length > 0
      && sports
      && !isFilteredByFavouriteSports
    ) {
      const favouriteSportsOption = getUserFavouriteSportsFilterValue();

      return [favouriteSportsOption, ...options];
    }

    return options;
  };

  const sportsOptions
  = defineSportsOptions();

  const handleSportsFilter = (selectedSports: OptionSportsType[]) => {
    // convert JSX label to string
    const selectedValues = selectedSports.map(item => ({
      ...item,
      label: item.title,
    }));

    // get favorite sport option if it exists
    const favouriteSportsSelected = selectedSports?.find(
      sportOption => sportOption.mysports,
    );

    const favouriteSportsIds = favouriteSportsSelected?.mysports.map(s => s.id);

    const filteredSelectedSports = favouriteSportsSelected
      ? selectedValues.filter(
        s => !favouriteSportsIds?.includes(s.id as number),
      )
      : selectedValues;

    handleSetSportsFilter(filteredSelectedSports);

    scrollTo(0, 0);
  };

  const handleSearchInput = (search: string, { action }) => {
    if (action === 'input-change')
      setInputValue(search);
    if (action === 'input-blur' || action === 'menu-close')
      setInputValue('');
  };

  const handleOptionSportsFilter = (
    sport: {
      label: string;
      value: string;
      data: OptionSportsType;
    },
    input: string,
  ) => {
    const normalizer = (value: string) =>
      value
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
        .toLowerCase();

    const normalizedInput = normalizer(input);

    return (
      sport.data.alias?.some(alias =>
        normalizer(alias).includes(normalizedInput),
      ) || normalizer(sport.data.title).includes(normalizedInput)
    );
  };

  const handleMenuOpen = () => {
    !sports && handleFetchSports();
  };

  return (
    <Select
      aria-label={t('PLACEHOLDER')}
      hideSelectedOptions={false}
      styles={layout.sportsSelectStyles}
      isDisabled={isFetching}
      instanceId="filterSport"
      isMulti
      options={sportsOptions}
      isLoading={isFetchingSports}
      onMenuOpen={handleMenuOpen}
      onChange={handleSportsFilter}
      closeMenuOnSelect={false}
      placeholder={(
        <span className="sports-filter__placeholder">
          <VpIcon name="search" size={16} />
          {t('PLACEHOLDER')}
        </span>
      )}
      loadingMessage={() => t('LOADING')}
      noOptionsMessage={() => t('NO_OPTION')}
      value={sportsFilter}
      components={{
        Option: IconOption,
        DropdownIndicator,
        ClearIndicator,
        MultiValue,
      }}
      blurInputOnSelect={false}
      inputValue={inputValue}
      onInputChange={handleSearchInput}
      filterOption={handleOptionSportsFilter}
    />
  );
}
