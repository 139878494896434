'use client';

import { ProjectsFiltersSports, ProjectsFiltersStatus } from '../components';
import { useAuthenticatedContext } from '@/app/components/Provider';
import { MainColumn } from '@/app/components/MainColumn';
import { isMainUser } from '~/utils/user/user';

export function ProjectsFilters() {
  const { user } = useAuthenticatedContext();

  return (
    <div className="projects-filter">
      <MainColumn>
        <div className="projects-filter__inner">
          {isMainUser(user) && (
            <div className="projects-filter__status">
              <ProjectsFiltersStatus />
            </div>
          )}
          <div className="projects-filter__sport">
            <ProjectsFiltersSports />
          </div>
        </div>
      </MainColumn>
    </div>
  );
}
