import type { ReactNode } from 'react';

export const MainColumn = ({
  children,
  container = 'container-fluid-centered',
  column = 'col-12',
}: {
  children: ReactNode;
  container?:
    | 'container'
    | 'container-fluid'
    | 'container-fluid-centered'
    | 'container-nested';
  column?: string;
}) => {
  return (
    <div className={container}>
      <div className="row">
        <div data-testid="MainColumn" className={column}>
          {children}
        </div>
      </div>
    </div>
  );
};
