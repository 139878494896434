import { VpIcon } from '@vtmn-play/react/headless';
import type { DropdownIndicatorProps } from 'react-select';
import { components } from 'react-select';

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <VpIcon name="chevron-down" />
    </components.DropdownIndicator>
  );
};
