import { useTranslations } from 'next-intl';
import { MaintenanceClouds } from '../../maintenance/components/MaintenanceClouds';
import { Beardman404 } from '~/components/Beardman/Beardman404/Beardman404';

export function ProjectsListEmpty() {
  const t = useTranslations('PROJECTS');
  return (
    <div className="maintenance-page__wrapper">
      <div className="maintenance-page__message">
        <p className="vp-title-m">{t('NONE')}</p>
        <Beardman404 />
        <MaintenanceClouds />
      </div>
    </div>
  );
}
